import { AdminStatus } from '../../../../utils/constants';
import Dimens from '../../../../theme/dimens';
import useCommonStyles from '../../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the AdminsTab component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

	const { xs, lg, xl, section } = Dimens.spacing;
	const theme = useTheme();
	const commonStyles = useCommonStyles();

	const styles = {
		wrapper: {
			display: 'flex',
			flex: 1,
			height: 'inherit',
		},
		innerWrapper: {
			flexDirection: 'column',
			display: 'flex',
			flex: 1,
			p: section,
			[ theme.breakpoints.down('sm') ]: {
				p: lg
			}
		},
		searchHeader: {
			display: 'flex',
			flexDirection: 'row',
			pb: section,
			[ theme.breakpoints.down('sm') ]: {
				pb: lg
			}
		},
		searchBarWrapper: {
			flex: 1,
			mr: xs
		},
		inviteIcon: {
			...commonStyles.smIcon,
			cursor: 'pointer'
		},
		tableContainer: {
			flex: 1,
			backgroundImage: 'none',
			border: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
			borderRadius: Dimens.radius.md,
			boxShadow: 'none',
			'& th': {
				bgcolor: theme.palette.background.paper,
				borderBottom: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
			},
			'& td': {
				borderBottom: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
			}
		},
		footer: {
			my: xl,
			// borderTop: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
			bottom: 0,
			px: 0,
			pt: xl,
			bgcolor: theme.palette.background.paper,
			position: 'sticky',
			textAlign: 'end',
			[ theme.breakpoints.down('sm') ]: {
				textAlign: 'center',
				display: 'flex',
				'& button': {
					flex: 1
				}
			}
		},
		statusCell: (status?: AdminStatus) => ({
			display: 'flex',
			alignItems: 'center',
			color: () => {
				let color = theme.palette.text.secondary;
				if (status === AdminStatus.Active) {
					color = theme.palette.success.main;
				} else if (status === AdminStatus.Inactive) {
					color = theme.palette.error.main;
				} else if (status === AdminStatus.InProgress) {
					color = theme.palette.text.primary;
				} 

				return color;
			}
		}),
		statusDot: (status: string) => ({
			height: Dimens.statusIconSize,
			width: Dimens.statusIconSize,
			borderRadius: Dimens.radius.xl,
			bgcolor: () => {
				let color = theme.palette.text.secondary;
				if (status === AdminStatus.Active) {
					color = theme.palette.success.main;
				} else if (status === AdminStatus.Inactive) {
					color = theme.palette.error.main;
				} else if (status === AdminStatus.InProgress) {
					color = theme.palette.text.primary;
				} 

				return color;
			},
			mr: Dimens.spacing.sm 
		}),
		binIcon:{
			fontSize: `${Dimens.icon.sm} !important`,
			color: theme.palette.error.main,
			cursor: 'pointer'
		}
	};

	return styles;
};

export default useStyles;