import Dimens from '../../../theme/dimens';
import useCommonStyles from '../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the OrganizationDetailScreen component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

  const { xxs, xs, base, lg, xl, section } = Dimens.spacing;
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const styles = {
    wrapper: {
      display: 'flex',
      height: 'inherit'
    },
    innerWrapper: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      m: section,
      borderRadius: Dimens.radius.lg,
      bgcolor: theme.palette.background.paper,
      [theme.breakpoints.up('md')]: {
        m: `${section} ${section} ${section} 0`
      },
      [theme.breakpoints.down('sm')]: {
        m: lg
      }
    },
    heading: {
      fontWeight: Dimens.fontWeight.semiBold,
      p: section,
      [theme.breakpoints.down('sm')]: {
        p: `${xl} ${lg}`
      }
    },
    tabListWrapper: {
      flex: 1,
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      minHeight: 0,
    },
    tabList: {
      borderBottom: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
      borderColor: theme.palette.background.default,
      px: section,
      minHeight: 0,
      [theme.breakpoints.down('sm')]: {
        px: lg
      }
    },
    tab: {
      ...theme.typography.p1,
      fontWeight: Dimens.fontWeight.medium,
      textTransform: 'none',
      mr: section,
      p: `${base} 0`,
      minHeight: 0,
      minWidth: 0
    },
    tabPanel: {
      p: 0,
      display: 'flex',
      overflow: 'hidden',
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        px: xxs
      }
    },
    image: {
      flex: 1,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    btnWrapper: {
      ...commonStyles.borderTop,
      p: `${lg} ${xl}`,
      display: 'flex',
      justifyContent: 'right'
    },
    multiBtnWrapper: {
      p: `${lg} ${xl}`,
      pt: 0,
      display: 'flex',
      justifyContent: 'space-between',
      '& .MuiButton-colorSecondary': {
        mr: xs
      }
    }
  };

  return styles;
};

export default useStyles;