import * as yup from 'yup'

import {
  MAX_MULTI_LINE_CHAR_COUNT,
  MAX_SINGLE_LINE_CHAR_COUNT,
  PWD_MIN_LENGTH,
  REGEX_PATTERN_LOWER_CASE,
  REGEX_PATTERN_NO_NUMBERS,
  REGEX_PATTERN_NUMBER,
  REGEX_PATTERN_PWD_SPECIAL_CHAR,
  REGEX_PATTERN_UPPER_CASE,
} from './constants'

import { t } from 'i18next'

/**
 * Validation schema definitions for all forms used in the application.
 *
 * The schemas utilize Yup validation methods and leverage i18next for localized error messages.
 */

// RegisterProfileScreen Validation
export const adminSchema = yup.object().shape({
  firstName: yup.string().trim()
    .max(MAX_SINGLE_LINE_CHAR_COUNT, t('charCountExceed', {
      count: MAX_SINGLE_LINE_CHAR_COUNT,
      field: t('firstName')
    }))
    .matches(REGEX_PATTERN_NO_NUMBERS, t('numberNotAllowed'))
    .required(t('fieldRequired', { field: t('firstName') })),
  lastName: yup.string().trim()
    .max(MAX_SINGLE_LINE_CHAR_COUNT, t('charCountExceed', {
      count: MAX_SINGLE_LINE_CHAR_COUNT,
      field: t('lastName')
    }))
    .matches(REGEX_PATTERN_NO_NUMBERS, t('numberNotAllowed'))
    .required(t('fieldRequired', { field: t('lastName') })),
  loginId: yup
    .string()
    .trim()
    .required(t('fieldRequired', { field: t('email') }))
    .email(t('fieldInvalid', { field: t('email') }))
})

export const OrgDetailSchema = yup.object().shape({
  name: yup.string().trim()
    .required(t('orgNameRequired'))
    .max(
      MAX_SINGLE_LINE_CHAR_COUNT,
      t('charCountExceed', {
        count: MAX_SINGLE_LINE_CHAR_COUNT,
        field: t('name')
      })
    ),
  licenceId: yup.number()
    .required(t('licenceRequired')),
  supportEmail: yup.string().trim()
    .required(t('fieldRequired', { field: t('email') }))
    .email(t('fieldInvalid', { field: t('email') })),
  description: yup.string().trim()
    .max(MAX_MULTI_LINE_CHAR_COUNT, t('charCountExceed', {
      count: MAX_MULTI_LINE_CHAR_COUNT,
      field: t('description')
    }))
});

export const PasswordSchema = yup.object({
  password: yup.string()
    .required()
    .min(PWD_MIN_LENGTH)
    .matches(REGEX_PATTERN_LOWER_CASE)
    .matches(REGEX_PATTERN_UPPER_CASE)
    .matches(REGEX_PATTERN_NUMBER)
    .matches(REGEX_PATTERN_PWD_SPECIAL_CHAR)
});
