import * as yup from 'yup';

import { Box, IconButton, InputAdornment, Typography, useTheme } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import APP_NAV from '../../routes/app-nav';
import AlertDialogLarge from '../../components/AlertDialogLarge';
import CircularProgressBar from '../../components/CircularProgressBar';
import CustomButton from '../../components/CustomButton';
import CustomInput from '../../components/CustomInput';
import EyeIcon from '../../components/CustomIcons/EyeIcon';
import EyeOffIcon from '../../components/CustomIcons/EyeOffIcon';
import InlineAlert from '../../components/InlineAlert';
import LogoHeader from '../../components/LogoHeader';
import SessionTimeOutDark from '../../assets/session_time_out_dark.svg';
import SessionTimeOutLight from '../../assets/session_time_out_light.svg';
import SignInLayout from '../../layouts/SignInLayout';
import SignInRequest from '../../types/sign-in-request';
import ThemeUtil from '../../theme/theme-util';
import { useSignInApi } from '../../data/sign-in/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Sign In Screen component for user login.
 *
 * This component renders a login form with email, password fields, and a submit button.
 * It uses Formik for form handling and yup for form validation. It also utilizes custom
 * components like `CustomInput`, `CustomButton`, and `InlineAlert` for improved UI.
 *
 * Features:
 *  - Form validation with Yup schema for email and password.
 *  - Internationalization using react-i18next for labels and messages.
 *  - Custom UI components for better user experience.
 *  - Submit functionality to trigger an API call for user login.
 */
const SignInScreen: React.FC = () => {

  const styles = useStyles();
  const isLightTheme = ThemeUtil.isLightTheme(useTheme());
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const signInApi = useSignInApi();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showSessionExpiredDialog, setShowSessionExpiredDialog] = useState<boolean>(Boolean(location.state?.isSessionTimeOut));
  const LOGIN_VALIDATION_SCHEMA = yup.object().shape({
    loginId: yup.string().trim()
      .required(t('emailRequired'))
      .email(t('emailInvalid')),
    password: yup.string().trim()
      .required(t('pwdRequired'))
  });
  const initialLoginRequest: SignInRequest = {
    loginId: '',
    password: '',
    clientId: ''
  };

  /**
   * Handles side effects upon component mount.
   * 
   * This effect checks the location state for a potential "isSessionTimeOut" flag
   * indicating the user's session has expired. If found, it performs two actions:
   *  1. Prevents state accumulation on navigation using `window.history.replaceState`.
   *  2. Shows a session timeout alert using the `setShowAlert` state setter.
   */
  useEffect(() => {
    if (location.state?.isSessionTimeOut) {
      window.history.replaceState(undefined, '');
    }
  }, []);

  /**
   * Handles the click event on the "Forgot Password" link.
   * 
   * Navigate the user to password reset page.
   */
  const onForgotPwdClick = () => {
    navigate(APP_NAV.FORGOT_PASSWORD);
  }

  /**
   * Handles form submission triggered by the Formik form.
   * 
   * This function retrieves the form data (`values`) from the Formik state
   * and spreads it into a new `SignInRequest` object to ensure proper data structure.
   * It then calls the `performSignIn` function from the `useSignInApi` hook
   * to initiate the user sign-in process with the login credentials.
   * 
   * @param {SignInRequest} request - The login request object containing loginId and password from the form.
   */
  const onSubmit = (request: SignInRequest) => {
    signInApi.performSignIn({ ...request });
  }

  return (
    <SignInLayout>
      <Box sx={styles.innerWrapper}>
        <LogoHeader heading={t('welcomeBack')} subHeading={t('signInMsg')} />
        <Formik
          validateOnMount
          validationSchema={LOGIN_VALIDATION_SCHEMA}
          initialValues={initialLoginRequest}
          onSubmit={values => onSubmit(values)}>
          {({values, isValid }) => (
            <Form>
              <InlineAlert message={signInApi.state.apiStatus?.error} />
              <Field name='loginId' placeholder={t('email')} component={CustomInput} />
              <Field name='password'              
                placeholder={t('password')} 
                hasFooter component={CustomInput}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  values.password &&
                  <InputAdornment position='end'>
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
                      {showPassword ? <EyeIcon sx={styles.icon} /> : <EyeOffIcon sx={styles.icon} />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Typography variant={'footer'} sx={styles.footer} onClick={onForgotPwdClick}>{t('forgotPwd')}</Typography>
              <Box sx={styles.spacer} />
              <CustomButton type='submit' title={t('signIn')} color='primary' disabled={!isValid} fullWidth />
            </Form>
          )}
        </Formik>
      </Box>
      <CircularProgressBar show={signInApi.state.apiStatus?.isLoading} />
      <AlertDialogLarge
        open={showSessionExpiredDialog}
        title={t('sessionExpired')}
        message={t('sessionExpiredMsg')}
        titleIcon={isLightTheme ? SessionTimeOutLight : SessionTimeOutDark}
        primaryLabel={t('signIn')}
        onPrimaryAction={() => setShowSessionExpiredDialog(false)}
        onClose={() => setShowSessionExpiredDialog(false)}
      />
    </SignInLayout>
  );
};

export default SignInScreen;
