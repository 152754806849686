/**
 * Dimens constants for UI measurement values.
 * 
 * This file defines various UI measurement values used throughout the application, 
 * including font sizes, font weights, spacing, and border radius values. 
 * These values are configurable and can be used to maintain a consistent design 
 * system throughout the application.
 */

import ThemeUtil from './theme-util';

// **Typography Constants**

/**
 * Base font size for HTML elements.
 */
export const HTML_FONT_SIZE = 18; // Default is 16 in MUI

/**
 * Default font size for typography elements.
 */
export const TYPOGRAPHY_FONT_SIZE = 14; // Default from MUI

/**
 * Scaling factor for typography sizes.
 */
export const SCALE = 1.125; // Typography scaling factor

/**
 * Dimens object containing various UI measurement values.
 */
const Dimens = {
  fontSize: {
    /**
     * Font sizes for various heading levels.
     * 
     * These values are calculated using `pxToRem` and `scaledValue`.
     */
    display1: ThemeUtil.scaledPixeltoRem(9),
    display2: ThemeUtil.scaledPixeltoRem(8),
    display3: ThemeUtil.scaledPixeltoRem(7),
    h1: ThemeUtil.scaledPixeltoRem(6),
    h2: ThemeUtil.scaledPixeltoRem(5),
    h3: ThemeUtil.scaledPixeltoRem(4),
    h4: ThemeUtil.scaledPixeltoRem(3),
    h5: ThemeUtil.scaledPixeltoRem(2),
    h6: ThemeUtil.scaledPixeltoRem(1),
    subHeading: ThemeUtil.scaledPixeltoRem(1),
    p1: ThemeUtil.scaledPixeltoRem(0),
    p2: ThemeUtil.scaledPixeltoRem(1),
    p3: ThemeUtil.scaledPixeltoRem(2),
    caption: ThemeUtil.scaledPixeltoRem(-1),
    footer: ThemeUtil.scaledPixeltoRem(-1)
  },
  fontWeight: {
    /**
     * Font weight options for various UI elements.
     */
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
  },
  spacing: {
    /**
     * Spacing values for margins and paddings in rem units.
     * 
     * These values are calculated using `pxToRem`.
     */
    xxs: ThemeUtil.pxToRem(4),
    xs: ThemeUtil.pxToRem(8),
    sm: ThemeUtil.pxToRem(10),
    base: ThemeUtil.pxToRem(12),
    md: ThemeUtil.pxToRem(14),
    lg: ThemeUtil.pxToRem(16),
    xl: ThemeUtil.pxToRem(20),
    section: ThemeUtil.pxToRem(24),
    box: ThemeUtil.pxToRem(28),
    container: ThemeUtil.pxToRem(32),
    card: ThemeUtil.pxToRem(40),
    paper: ThemeUtil.pxToRem(44)
  },
  radius: {
    /**
     * Border radius values for UI elements in rem units.
     * 
     * These values are calculated using `pxToRem`.
     */
    xs: ThemeUtil.pxToRem(2),
    sm: ThemeUtil.pxToRem(4),
    md: ThemeUtil.pxToRem(8),
    lg: ThemeUtil.pxToRem(12),
    xl: ThemeUtil.pxToRem(20),
    xxl: ThemeUtil.pxToRem(30)
  },
  icon: {
    /**
     * Font size values for Icons in rem units.
     * 
     * These values are calculated using `pxToRem`.
     */
    sm: '1rem',
    md: '1.5rem',
    lg: '2rem',
    xl: '4rem'
  },
  inputBorderWidth: ThemeUtil.pxToRem(2),
  menuBorderWidth: ThemeUtil.pxToRem(2.5),
  menuItemMinWidth: ThemeUtil.pxToRem(180),
  loginLayoutWidth: ThemeUtil.pxToRem(400),
  singleInput: {
    focusLineWidth: ThemeUtil.pxToRem(44),
    width: ThemeUtil.pxToRem(46),
    height: ThemeUtil.pxToRem(51),
  },
  buttonMinWidth: ThemeUtil.pxToRem(184),
  alertDialogSmallWidth: {
    lg: ThemeUtil.pxToRem(440),
    sm: ThemeUtil.pxToRem(420)
  },
  alertDialogLargeWidth: {
    lg: ThemeUtil.pxToRem(800),
    md: ThemeUtil.pxToRem(520)
  },
  avatarSize: ThemeUtil.pxToRem(48),
  switch: {
    width: ThemeUtil.pxToRem(48),
    height: ThemeUtil.pxToRem(30),
    thumbSize: ThemeUtil.pxToRem(22)
  },
  dialogContentMaxHeight: ThemeUtil.pxToRem(450),
  statusIconSize: ThemeUtil.pxToRem(12),
  highlightIconPadding: ThemeUtil.pxToRem(6),
}

export default Dimens;
