import { Box, Toolbar, useTheme } from '@mui/material';

import AlertDialogLarge from '../../../components/AlertDialogLarge';
import React from 'react';
import SessionTimeOutDark from '../../../assets/session_time_out_dark.svg';
import SessionTimeOutLight from '../../../assets/session_time_out_light.svg';
import ThemeUtil from '../../../theme/theme-util';
import TopBar from './TopBar';
import { useSessionHandler } from '../../../hooks/use-session-handler';
import { useSignInApi } from '../../../data/sign-in/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

export interface MainProps {
  children: React.ReactNode;
}

/**
 * Main application layout component for logged-in users.
 * 
 * This component renders the main application layout for users who have successfully logged in.
 * It provides the following functionalities:
 *  - Displays the `TopBar` component, likely containing navigation options and logout functionality.
 *  - Handles user logout triggered through the `onLogOutClick` prop passed to `TopBar`.
 *  - Utilizes the `useSessionHandler` hook for session management tasks:
 *      - Idle time monitoring and automatic logout after inactivity for a period defined by `SESSION_TIME_OUT` 
 *        (likely from `SESSION_DEBOUNCE_TIME` and `SESSION_TIME_OUT` constants).
 *      - Potential automatic refresh of access tokens to maintain uninterrupted access.
 *  - Renders the provided children components within the main content area.
 *
 * Props:
 *  - `children` (React.ReactNode, required): Content to be displayed within the main application area.
 *
 * @returns {JSX.Element} - The rendered main application layout component.
 */
const Main: React.FC<MainProps> = (props: MainProps) => {

  const styles = useStyles();
  const signInApi = useSignInApi();
  // Call session handler hook for session/idle time management.
  const { showTimeOutDialog, resetTimer, handleOnUserIdle } = useSessionHandler();
  const { t } = useTranslation();

  /**
   * Handles the user clicking the "Logout" button in the top bar.
   * 
   * This function is called when the user interacts with the logout functionality
   * typically provided through a button or menu option in the `TopBar` component.
   * It initiates a user-initiated logout process by calling `performSignOut`
   * from the `useSignInApi` hook, likely clearing session data and potentially
   * redirecting the user to the login page.
   */
  const handleLogOut = () => {
    signInApi.performSignOut(false);
  }

  return (
    <Box sx={styles.root}>
      <TopBar onLogOutClick={handleLogOut} />
      <Box sx={styles.contentWrapper}>
        {/* This is a dummy toolbar for adjusting the top padding */}
        <Toolbar />
        <Box component='main' sx={styles.content}>
          {props.children}
        </Box>
      </Box>
      <AlertDialogLarge
          open={showTimeOutDialog}
          title={t('sessionTimeout')}
          message={t('sessionTimeoutMsg')}
          titleIcon={ThemeUtil.isLightTheme(useTheme()) ? SessionTimeOutLight : SessionTimeOutDark}
          secondaryLabel={t('signOutSpace')}
          onSecondaryAction={() => handleOnUserIdle()}
          primaryLabel={t('continueSession')}
          onPrimaryAction={() => resetTimer()}
          onClose={() => resetTimer()}
        />
    </Box>
  );
}

export default Main;
